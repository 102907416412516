import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/common/Layout';
import Prices from '../../components/pricing/Prices';

const PricesPage = () => (
  <Layout lang="de">
    <SEO
      title="Preise - Nextcloud Hosting"
      description="Übersicht aller unser Abos und Preise"
    />
    <Prices lang="de" minWidth={1900} />
  </Layout>
);

export default PricesPage;
